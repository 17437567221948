import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {StaticImage} from "gatsby-plugin-image";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const Contacts = () => (
    <Layout>
        <SEO title="Контакты" />
        <StaticImage
            src="../images/header.jpg"
            width={1600}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Горка Инжиниринг"
            layout={'constrained'}
            objectPosition={'center 60%'}
            style={{
                width: '100%'
            }}
        />
        <div className="container">
            <h1>Контакты</h1>
            <p>ООО «ГОРКА Инжиниринг»</p>
            <p>г. Москва, Кантемировская ул, дом 53, корпус 1, этаж 1, помещение 5, комната 2</p>
            <p><a className={'tel'} href='tel:84956629493'>+7 (495) 909 98 17</a></p>
            <a href="mailto:info@gorka-engin.ru" className={'mail'}>info@gorka-engin.ru</a>
        </div>
    </Layout>
)

export default Contacts